<template>
  <el-dialog title="关闭" width="500px" :visible="closeShow" :close-on-click-modal="false" @close='closeDialog'>
    <el-form :model="info" ref="info" label-width="80px">
      <el-form-item label="关闭说明" prop="handleBak">
        <el-input
          type="textarea"
          maxlength="100"
          placeholder="请输入关闭说明"
          :rows="4"
          show-word-limit
          v-model="info.handleBak"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" :disabled="submitShow">确定</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'IssueClose',
  props: {
    closeShow: Boolean,
    issueId: String
  },
  data () {
    return {
      info: {
        handleBak: ''
      },
      submitShow: false
    }
  },
  created () {
    // ...
  },
  methods: {
    onSubmit () {
      this.submitShow = true
      this.$axios.post(this.$apis.patrol.closeIssue, {
        issueId: this.issueId,
        handleBak: this.info.handleBak
      }).then(() => {
        this.$message.success('关闭成功')
        this.$emit('success')
        this.closeDialog()
      }).finally(() => {
        this.submitShow = false
      })
    },
    // 关闭
    closeDialog () {
      this.$emit('update:closeShow', false)
      this.$refs.info.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
