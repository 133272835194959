
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '../../../utils/decorators'
import WorkOrderClose from './Close.vue'
import WorkOrderTransfer from './Transfer.vue'
import WorkOrderFeedback from './Feedback.vue'
import WorkOrderAudit from './Audit.vue'

@Component({
  components: { WorkOrderClose, WorkOrderTransfer, WorkOrderFeedback, WorkOrderAudit }
})
@KeepAlive
export default class IssueList extends Vue {
  private searchForm: {
    issueContent: string;
    projectId: string;
    startTime: string;
    endTime: string;
    safeManageId: string;
    iStatus: string;
    issueStatus: string;
    isSelfHandle: string;
  } = {
    issueContent: '',
    projectId: '',
    startTime: '',
    endTime: '',
    safeManageId: '',
    iStatus: '',
    issueStatus: '',
    isSelfHandle: '1'
  }

  private tabType = 'my'
  private safeManageList = []
  private date = []
  private minDate = 0
  private loading = false
  private issueList = []
  page = 1
  size = 10
  total = 0
  private closeShow = false
  private transferShow = false
  private feedbackShow = false
  private auditShow = false
  private issueId = ''
  private issueInfo = {}
  private transInfo = {}

  private pickerOptions = {
    // 选中日期后会执行的回调
    // 日期范围不超过180天 ，选中当天之前的时期
    onPick: (date: any) => {
      this.minDate = date.minDate.getTime()
      if (date.maxDate) {
        this.minDate = 0
      }
    },
    // 限制最大一个月且不能大于当前时间
    disabledDate: (time: any) => {
      if (this.minDate) {
        const one = 180 * 24 * 3600 * 1000
        const minTime = this.minDate - one
        const maxTime = this.minDate + one
        return time.getTime() > (Date.now() - 8.64e6) || (time.getTime() < minTime || time.getTime() > maxTime)
      } else {
        return time.getTime() > Date.now()
      }
    }
  }

  get userId () {
    return this.$store.state.userInfo.userId || ''
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadSafeManageList()
    this.loadData()
  }

  changeDate (date: any) {
    this.searchForm.startTime = (date && date[0]) || ''
    this.searchForm.endTime = (date && date[1]) || ''
  }

  loadSafeManageList () {
    this.$axios.get(this.$apis.patrol.selectSafeManageList).then(res => {
      this.safeManageList = res || []
    })
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.patrol.selectIssueByPage, {
      page: this.page,
      size: this.size,
      ...this.searchForm
    }).then(res => {
      this.issueList = res.list || []
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.searchForm.isSelfHandle = this.tabType === 'my' ? '1' : ''
    this.page = 1
    this.loadData()
  }

  onTransfer (row: any) {
    this.issueId = row.issueId
    this.transInfo = {
      projectId: row.projectId,
      currentSafeManageId: row.currentSafeManageId,
      currentHandleUserId: row.currentHandleUserId
    }
    this.transferShow = true
  }

  onFeedback (row: any) {
    this.issueInfo = {
      issueId: row.issueId,
      projectId: row.projectId,
      currentSafeManageId: row.currentSafeManageId
    }
    this.feedbackShow = true
  }

  onAudit (issueId: string) {
    this.issueId = issueId
    this.auditShow = true
  }

  onDetail (issueId: string) {
    this.$router.push({ name: 'patrolIssueDetail', params: { issueId } })
  }

  onClose (issueId: string) {
    this.issueId = issueId
    this.closeShow = true
  }
}
